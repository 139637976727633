import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppSwitch } from '@t4b/core/lib'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { OrderSide, OrderType, ProcessingRuleEntity, RoutingRuleEntity, Schedule, ScheduleType } from '../../entity/configuration'
import { IMultiSelectItem } from '../../entity/multiselect'
import { GatewayEntity } from '../../entity/system-settings'
import withGateway, { IGatewayProp } from '../../hocs/withGateway'
import { useFormValidation } from '../../hooks/useFormValidation'
import { addRoutingRule, changeRoutingRule, executionConfigurationChanged } from '../../redux/actions/execution/execution-actions'
import { hideRightBar } from '../../redux/actions/rightbar-actions'
import { blankInput, buildControlsExtTwoPerLine, colorsInput, mselectInput, selectInput, textInput } from '../../utils/controls'
import { buildMultiselectOptionsFromArray, buildMultiselectOptionsFromEnum, buildOptionsWithAll, isAllOption, isEqualArrays, optionsToStrings } from '../../utils/multiselect-utils'
import AppSchedule from '../AppSchedule'
import CheckBoxInput from '../inputs/CheckBoxInput'
import { IRightbar } from './rightbar-types'

interface IRoutingRuleRightbarProps extends IRightbar, IGatewayProp {}

function buildPlatformsOptions(gateway: GatewayEntity): IMultiSelectItem[] {
  return buildMultiselectOptionsFromArray(gateway.Platforms.filter(item => item.Type !== 'mtexec').map(item => item.Name))
}

function isColorEnabled(rule: RoutingRuleEntity): boolean {
  return rule.Colors?.length !== 0 || rule.IsAllowedColors
}

const selectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
}

const RoutingRuleRightbar: React.FC<IRoutingRuleRightbarProps> = React.memo(({ data: { type, item, preset }, gateway }) => {
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    new RoutingRuleEntity({
      ...item,
      MaxVolume: String(item.MaxVolume),
      MinVolume: String(item.MinVolume),
    }),
    RoutingRuleEntity.schema,
  )
  const [schedule, setSchedule, scheduleTouched, setScheduleTouched, scheduleErrors, isScheduleValid] = useFormValidation(new Schedule(item.Schedule), Schedule.schema)
  const dispatch = useDispatch()
  const { processingRules, Profile, routingRules, Countries } = useSelector((state: any) => state.executionConfiguration)
  const scheduleRef = useRef<any>(null)
  const [colorsEnabled, setColorsEnabled] = useState({ UseColorFilter: isColorEnabled(inputState) })
  const [activeKey, setActiveKey] = useState<any>('0')
  const nameLpPool = Profile.map((item: any) => item.Name)
  const processingRulesName = processingRules.filter((el: any) => el.Profile === inputState.Profile)

  useEffect(() => {
    if (!inputState.Profile && type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          Profile: preset === 'All' ? nameLpPool[0] : preset,
        }
      })
    }
  }, [Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputState.Profile && type === 'add') {
      setInputState((prev: any) => {
        return {
          ...prev,
          ProcessorName: processingRulesName[0]?.Name ?? '',
          ProcessorId: processingRulesName[0]?.Id ?? '',
        }
      })
    }
  }, [inputState.Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        ProcessorId: processingRulesName?.find((item: any) => item?.Name === inputState.ProcessorName)?.Id ?? processingRulesName[0]?.Id,
      }
    })
  }, [inputState.ProcessorName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'modify') {
      setInputState((prev: any) => {
        return {
          ...prev,
          ProcessorName: processingRulesName?.find((item: any) => item?.Name === inputState?.ProcessorName)?.Name || processingRulesName?.find((item: any) => item?.Id === inputState?.ProcessorId)?.Name || processingRulesName[0]?.Name,
          ProcessorId: processingRulesName?.find((item: any) => item?.Name === inputState.ProcessorName)?.Id || processingRulesName?.find((item: any) => item?.Id === inputState.ProcessorId)?.Id || processingRulesName[0]?.Id,
        }
      })
    }
  }, [inputState.ProcessorName, inputState.Profile]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyExist = () => {
    if (type === 'modify' && routingRules.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile && platform.Id !== inputState.Id)) {
      errors.Name = true
      return 'routing.exists'
    }
    if ((type === 'add' || type === 'clone') && routingRules.find((platform: any) => platform.Name === inputState.Name && platform.Profile === inputState.Profile)) {
      errors.Name = true
      return 'routing.exists'
    }
    return ''
  }

  const handleToggle = () => {
    if (activeKey) {
      setActiveKey(undefined)
    } else {
      setActiveKey('0')
    }
  }

  if (!colorsEnabled.UseColorFilter) {
    errors.Colors = false
  }

  const handleChange = (newState: any) => {
    if (!newState.UseColorFilter) {
      setInputState({
        ...inputState,
        IsAllowedColors: false,
        Colors: [],
      })
    }
    setColorsEnabled(newState)
  }

  const [msg, setMsg] = useState({ processingRulesName: '', Profile: '' })

  const handleSave = async () => {
    const cond1 = isValid()
    const cond2 = isScheduleValid()

    if (!cond1 || !processingRulesName.map((item: ProcessingRuleEntity) => item.Name).length || !presetName.length) {
      if (!presetName.length) {
        errors.Profile = true
        touched.Profile = true
        setMsg((prev: any) => {
          return {
            ...prev,
            Profile: 'You need to create a profile to proceed',
          }
        })
      }

      if (!processingRulesName.map((item: ProcessingRuleEntity) => item.Name).length) {
        errors.Profile = true
        touched.Profile = true
        setMsg((prev: any) => {
          return {
            ...prev,
            processingRulesName: 'You need to create a processing rule to proceed',
          }
        })
      }

      // ref.current?.open();
      if (!activeKey) {
        handleToggle()
      }
    }

    if (!cond2 && schedule.ScheduleType === ScheduleType.Periodic) {
      scheduleRef.current?.open()
    }

    if (!cond1 || (!cond2 && schedule.ScheduleType === ScheduleType.Periodic) || !processingRulesName.map((item: ProcessingRuleEntity) => item.Name).length || !presetName.length) {
      if (!presetName.length) {
        errors.Profile = true
        touched.Profile = true
        setMsg((prev: any) => {
          return {
            ...prev,
            Profile: 'You need to create a profile to proceed',
          }
        })
      }

      if (!processingRulesName.map((item: ProcessingRuleEntity) => item.Name).length) {
        errors.Profile = true
        touched.Profile = true
        setMsg((prev: any) => {
          return {
            ...prev,
            processingRulesName: 'You need to create a processing rule to proceed',
          }
        })
      }
      return
    }

    const findProfile = await Profile.filter((item: any) => item.Name === inputState.Profile)
    const data = await new RoutingRuleEntity({
      ...inputState,
      Schedule: schedule,
      Enabled: findProfile[0].Enabled,
      Countries: inputState.Countries.length ? inputState.Countries : [{ value: '*', label: '*' }],
    })

    if (type === 'add' || type === 'clone') {
      dispatch(addRoutingRule(data, true))
    } else {
      dispatch(changeRoutingRule(data))
    }
    dispatch(executionConfigurationChanged())
    dispatch(hideRightBar())
  }

  const setPlatformsState = (state: any) => {
    const all = 'All'

    if (state.Platforms?.find(isAllOption)) {
      setInputState({
        ...state,
        Platforms: buildMultiselectOptionsFromArray([all]),
      })
      return
    }
    setInputState(state)
  }

  const setCountriesState = (state: any) => {
    const all = '*'

    if (
      isEqualArrays(
        optionsToStrings(state.Countries)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        Countries: buildMultiselectOptionsFromArray([all]),
      })
      return
    }

    setInputState(state)
  }

  const setOrderTypesState = (state: any) => {
    const all = 'All'

    if (
      state.OrderTypes?.find(isAllOption) ||
      isEqualArrays(
        optionsToStrings(state.OrderTypes)
          .filter(option => option !== all)
          .sort(),
        Object.keys(OrderType)
          .filter(option => option !== all)
          .sort(),
      )
    ) {
      setInputState({
        ...state,
        OrderTypes: buildMultiselectOptionsFromArray([all]),
      })
      return
    }

    setInputState(state)
  }

  const presetName = Profile.map((item: any) => item.Name)

  const controls = buildControlsExtTwoPerLine(
    [
      textInput('Name').errorMessage(alreadyExist()),
      selectInput('Profile', presetName).errorMessage(msg.Profile),
      selectInput('ProcessorName')
        .optionItems(processingRulesName.map((item: ProcessingRuleEntity) => item.Name))
        .errorMessage(msg.processingRulesName),
      textInput('Logins', false, '', '', 'Mask', true),
      textInput('Groups', false, '', '', 'Mask', true),
      textInput('Symbols', false, '', '', 'Mask', true),
      textInput('Security', false, '', '', 'Mask', true),
      mselectInput('Countries', buildOptionsWithAll(buildMultiselectOptionsFromEnum(Countries), inputState.Countries), true, true, 'Add mask:', 'Mask', true)
        .styles(selectStyles)
        .stateSetup(setCountriesState),
      mselectInput('OrderTypes', buildOptionsWithAll(buildMultiselectOptionsFromEnum(OrderType), inputState.OrderTypes))
        .styles(selectStyles)
        .stateSetup(setOrderTypesState),
      selectInput('OrderSides').optionItems(Object.keys(OrderSide)),
      blankInput(),
      textInput('MinVolume'),
      textInput('MaxVolume'),
    ],
    inputState,
    setInputState,
    'routing-rule',
    touched,
    setTouched,
    errors,
  )

  const handleSwitch = useCallback(
    (checked: boolean) => {
      setInputState({
        ...inputState,
        IsAllowedColors: checked,
      })
    },
    [inputState, setInputState],
  )

  const cm = (
    <div className="row mb-3">
      <CheckBoxInput state={colorsEnabled} setState={handleChange} name="UseColorFilter" label="routing-rule.UseColorFilter" className="col-3 d-flex align-items-center m-0" />
      <div className="col-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className="mr-3">
          <FormattedMessage id="routing-rule.Except" />
        </span>
        <AppSwitch checked={inputState.IsAllowedColors} onChange={handleSwitch} disabled={!colorsEnabled.UseColorFilter} />
        <span className="ml-3">
          <FormattedMessage id="routing-rule.Allowable" />
        </span>
      </div>
    </div>
  )

  const controls1 = buildControlsExtTwoPerLine(
    [
      colorsInput('Colors').styles({ zIndex: 1 }).disabled(!colorsEnabled.UseColorFilter),
      mselectInput('Platforms', buildOptionsWithAll(buildMultiselectOptionsFromArray([...buildPlatformsOptions(gateway), 'All']), inputState.Platforms))
        .styles(selectStyles)
        .stateSetup(setPlatformsState),
    ],
    inputState,
    setInputState,
    'routing-rule',
    touched,
    setTouched,
    errors,
  )

  const headerStyle = activeKey !== '0' ? { color: '#8dabc4', border: 'none', fontWeight: 500 } : { color: '#8dabc4', fontWeight: 500 }
  const iconClass = activeKey !== '0' ? 'icon' : 'icon icon-rotate'

  if (+inputState.MinVolume > 1000000000 || inputState.MinVolume.slice(-1) === '.') {
    errors.MinVolume = true
  }

  if (+inputState.MaxVolume > 1000000000 || inputState.MaxVolume.slice(-1) === '.') {
    errors.MaxVolume = true
  }

  return (
    <>
      <Accordion activeKey={activeKey} className="app-accordion">
        <Card style={{ overflow: 'visible' }}>
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleToggle} className="accordion-header d-flex justify-content-between" style={headerStyle}>
            <FormattedMessage id={`routing-rule.${type}`} tagName="span" />
            <FontAwesomeIcon icon={faAngleRight} className={iconClass} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{[controls, cm, controls1]}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <AppSchedule state={schedule} touched={scheduleTouched} setTouched={setScheduleTouched} errors={scheduleErrors} isValid={isScheduleValid} setState={setSchedule} ref={scheduleRef} />

      <Button className="t4b-bg-dark-button my-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})

export default withGateway(RoutingRuleRightbar)
